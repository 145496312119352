/* React */
import React, { Component } from "react";

/* Plugins */
import { connect } from "react-redux";
import Player from "@vimeo/player";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MediaQuery from "react-responsive";
import Countdown, { zeroPad } from "react-countdown";

/* Redux */
import { CourseActions } from "../../../__actions/course.actions";
import { DiscussionsActions } from "../../../__actions/discussion.actions";
import { ToDoActions } from "../../../__actions/to_do.actions";
import { mapAnddispatchActionsToProps, getUserDetailsFromToken, toggleShowModal, isShowComponentToUser, hasInstallmentOverdue } from "../../../__helpers/helpers";
import { ModuleTypes, TIMEOUT_SPEED, MODULES_PAGE, CHAPTER_SURVEY_PAGE, USER_PRIVILEGES, ADMIN_USERS, KEY_CODES, VIEW_WIDTH, ELEMENT, WORKSPACE_ALLOWED_ASSIGNMENT_EARLY_ACCESS } from "../../../__config/constants";

/* Components */
import AddBookmarkModal from "./../modals/add_bookmark.modal";
import ChapterSurveyComponent from "./components/chapter_survey.component";
import DeleteDiscussionModuleModal from "./modals/delete_discussion_module.modal";
import EditDiscussionBoardModal from "./modals/edit_discussion_board.modal";
import FrequentlyAskedQuestionComponent from "./components/frequently_asked_question.component";
import HeaderComponent from "./../../global/components/header.component";
import LessonComponent from "./components/lesson.component";
import OnlineDiscussion from "./components/online_discussion.component";
import OnlineDiscussionCommentComponent from "./components/online_discussion_comments.component";
import PrivacyPolicyModal from "./modals/privacy_policy.modal";
import QuizComponent from "./components/quiz.component";
import ReportCurriculumMistake from "./components/report_curriculum_mistake.component";
import SidebarComponent from "./components/sidebar.component";
import SidebarOnlineDiscussionComponent from "./components/sidebar_online_discussion.component";
import SubNavigationComponent from "../../global/components/sub_navigation.component";
import TodoComponent from "./components/todo.component";
import TodoModal from "./modals/todo.modal";
import TodoSolutionModal from "../course/modals/todo_solution.modal";
import TodoSubmitComponent from "./components/todo_submit.component";
import AssignmentForumComponent from "./components/assignment_forum.component";
import SurveyModal from "./../modals/survey.modal";
import ChileCountdownBanner from "../../global/components/chile_countdown_banner.component";
import HeadComponent from "../../global/components/head.component";

/* Chile Sence Modals */ 
import FailedSenceLoginModal from "./../modals/chile_sence/failed_sence_login.modal";
import FailedSenceRequestModal from "../modals/chile_sence/failed_sence_request.modal";

/* CSS */ 
import "./course.scss";

/** 
* @class 
* @extends Component
* This component class is being called on the /layouts/user.layout.jsx <br>
* All methods are related to lesson, todo, quiz and chapter survey modules.<br>
* Last Updated Date: May 24, 2024
*/
class Course extends Component {
    constructor (props){
        super(props);
        this.discussion_position = React.createRef();
        this.module_description_container_position = React.createRef();
        this.state = { 
            is_show_add_bookmark_modal: false,
            is_show_bookmarks_modal: false,
            is_show_todo_modal: false,
            is_submit_chapter_survey_form: false,
            is_show_privacy_policy_modal: false,
            is_show_check_solution_timer: false,
            current_chapter_module_id: null,
            current_discussion_question_id: null,
            is_show_delete_discussion_modal: false,
            is_show_report_curriculum_popover: false,
            is_show_delete_discussion_module_modal: false,
            is_show_edit_discussion_board_modal: false,
            is_show_todo_solution_modal: false,
            is_solution_countdown_completed: true,
            notification_count: 0, 
            raw_notifications: [],
            is_show_survey_modal: true,
            cd_survey_scheds: [],
            mobile_show_module: false,
            is_show_assignment_forum_and_notifications: true,
            is_show_failed_sence_login_modal: false,
            is_show_sence_acknowledgement_modal : false,
            is_show_failed_sence_request_modal: this.props.location.pathname.indexOf('/error_code=') > -1,
            is_show_sence_countdown_timer: true,
            error_code: null,
            is_show_assignment_forum: true,
            is_typing:false
        }

        let get_user_details = getUserDetailsFromToken();



        if(get_user_details.status === true){
            this.state = {...this.state, profile: get_user_details.user_details}
        }
        
        this.onWindowLoadSubscription = undefined;
        this.vimeoPlayers = [];

        /* Call helper to check if the assignment forum and notification should be displayed*/ 
        this.state.is_show_assignment_forum = isShowComponentToUser(get_user_details.user_details, USER_PRIVILEGES.ASSIGNMENT_FORUM.id);

        this.previous_button = React.createRef();
        this.next_button = React.createRef();
    }    

    /**
    * DOCU: This will set active module when component is mounted and listen for key press. <br>
    * Triggered: Invoked immediately after this component is mounted. <br>
    * Last Updated Date: September 18, 2023
    * @function
    * @memberOf Course
    * @author Jerwin, Updated by: Mario, Psyrone, Alfonso, Christian, JeffreyCarl
    */        
    componentDidMount(){

        /* Redirect to the dashboard if the user has an installment overdue or user needs an alumni pass. */
        if(hasInstallmentOverdue() || this.state.profile.general.is_alumni_pass_needed){
            window.location.href = "/dashboard";
        }

        if(this.props.is_online_discussion === "true"){
            this.props.fetchOnlineDiscussion({
                track_id:     this.props.match.params.track_id,
                week_id:      this.props.match.params.week_id,
                question_id:  this.props.match.params.question_id,
                trial_platform_mode: this.props.location?.pathname?.includes("/t/m/")
            });             
        }
        else{
            this.props.fetchCoursePageData({
                track_id:           this.props.match.params.track_id,
                chapter_id:         this.props.match.params.chapter_id,
                chapter_module_id:  this.props.match.params.module_id,
                trial_platform_mode: this.props.location?.pathname?.includes("/t/m/")
            });

            /* Analytics. For Sence users that hasn't started their session yet, call function to update the timespent in course page. */
            window.addEventListener("beforeunload", () => { this.props.onUpdateUserSessionPage() });
        }

        window.scrollTo(0, 0);  
        window.addEventListener("resize", this.onResizeBrowser);
        if(window.innerWidth > VIEW_WIDTH.MOBILE){
            window.addEventListener("keyup", this.onKeyPress);
            document.addEventListener("focus", this.handleFocus, true);
            document.addEventListener("blur", this.handleBlur, true);
        }
    }


    /**
    * DOCU: If student clicked next/previous or when the url is changed when in the Course page <br>
    * Triggered: Invoked immediately after updating occurs on this component.  <br>
    * Last Updated Date: February 23, 2023
    * @function
    * @memberOf Course
    * @param {object} previousProps - Requires previous props for fetching online discussion
    * @author Mario, updated by Christian, Jomar, Jerwin, and JeffreyCarl, Alfonso
    */             
    componentDidUpdate = (previous_props, previous_state) =>{
        if(window.innerWidth > VIEW_WIDTH.MOBILE){
            window.addEventListener("keyup", this.onKeyPress);
            document.addEventListener("focus", this.handleFocus, true);
            document.addEventListener("blur", this.handleBlur, true);
        }
        else{
            window.removeEventListener("keyup", this.onKeyPress);
            document.removeEventListener('focus', this.handleFocus, true);
            document.removeEventListener('blur', this.handleBlur, true);
        }

        if(this.props.is_online_discussion === "true"){
            if(this.props && (previous_props.match.params.question_id !== this.props.match.params.question_id)){
                this.props.fetchOnlineDiscussion({
                    track_id:     this.props.match.params.track_id,
                    week_id:      this.props.match.params.week_id,
                    question_id:  this.props.match.params.question_id,
                });                
            }
        }
        else{
            if(this.props && (previous_props.match.params.module_id !== this.props.match.params.module_id) && !this.props.courses.is_lesson_module_submitted){
                this.props.fetchCoursePageData({
                    track_id:           this.props.match.params.track_id,
                    chapter_id:         this.props.match.params.chapter_id,
                    chapter_module_id:  this.props.match.params.module_id,
                    trial_platform_mode: this.props.location?.pathname?.includes("/t/m/")
                });

                /*  Code block that will check if the page content was changed or not.
                    This is for analytics purposes.
                    Owner: Christian, Moved by: JeffreyCarl */

                let { track } = this.props.courses;
                if(track.chapter_module_id !== undefined 
                    && this.state.current_chapter_module_id !== track.chapter_module_id 
                    && this.props.analytics.is_session_page_saving === false
                ){

                    this.setState({current_chapter_module_id: track.chapter_module_id});
                    this.props.onSaveUserSessionPage({
                        page_type_id        : (track.chapter_module_id !== "survey") ? MODULES_PAGE : CHAPTER_SURVEY_PAGE,
                        track_id            : track.track_id, 
                        course_id           : track.course_id, 
                        chapter_id          : track.chapter_id, 
                        chapter_module_id   : (track.chapter_module_id !== "survey") ? track.chapter_module_id : 0,                
                    });

                /* Added delay for checking of IFrames after it was loaded to trigger checking when user clicks the NEXT/PREVIOUS. */
                setTimeout(() => { this.checkIframes(); }, 200);
            }

                /* Remove the disable class for module navigation next and previous button */ 
                this.setState({ disable_next_previous_button: false });

                window.scrollTo(0, 0);      
                
                /* Hide Report Curriculum Mistake */ 
                this.onHideReportCurriculumMistake();  
            }
        }

        /* Update state if the is_show_failed_sence_login_modal in props has changed. */
        if(previous_props.courses.is_show_failed_sence_login_modal !== this.props.courses.is_show_failed_sence_login_modal){
            let is_show_failed_sence_login_modal = this.props.location.pathname.indexOf('/error_code=') > -1 ? false : this.props.courses.is_show_failed_sence_login_modal;
            this.setState({is_show_failed_sence_login_modal});
        }

        /* Add class to the body when showing delete discussion module modal */
        (this.state.is_show_delete_discussion_module_modal) ? document.body.classList.add("is_show_delete_discussion_modal") : document.body.classList.remove("is_show_delete_discussion_modal");
    }

    /**
    * DOCU: Added for Analytics that is being used for checking videos inside the Iframes. After the page was loaded. <br>
    * This is used for Analytics. <br>
    * Triggered: Invoked after render() is called <br>
    * Last Updated Date: September 2, 2020
    * @function
    * @memberOf Course
    * @author Christian, Updated by: JeffreyCarl
    */
    componentWillMount = () => {
        this.onWindowLoadSubscription = window.addEventListener("load", this.checkIframes, false);

        /*  Code block that will check if the page content was changed or not.
            This is for analytics purposes.
            Owner: Christian, Moved by: JeffreyCarl */

        if(this.props.analytics.is_session_page_updating === false){
            /*  Update the time stamp of the current user session page record for analytics purposes. */
            this.props.onUpdateUserSessionPage();
        }

        let { track } = this.props.courses;
        this.setState({current_chapter_module_id: track.chapter_module_id});
        this.props.onSaveUserSessionPage({
            page_type_id        : (track.chapter_module_id !== "survey") ? MODULES_PAGE : CHAPTER_SURVEY_PAGE,
            track_id            : track.track_id, 
            course_id           : track.course_id, 
            chapter_id          : track.chapter_id, 
            chapter_module_id   : (track.chapter_module_id !== "survey") ? track.chapter_module_id : 0,                
        });

        /* Added delay for checking of IFrames after it was loaded to trigger checking when user clicks the NEXT/PREVIOUS. */
        setTimeout(() => { this.checkIframes(); }, 200);

    }

    /**
    * DOCU: Free up the memory consumed when adding the load event listener for checking videos in iframes and the listener for key press, focus and blur. <br>
    * This is used for Analytics. <br>
    * Triggered: This method is called when course.jsx is being removed from the DOM <br>
    * Last Updated Date: February 20, 2023
    * @function
    * @memberOf Course
    * @author Christian, Updated by Jerwin, Alfonso
    */
    componentWillUnmount() {
        window.addEventListener("resize", this.onResizeBrowser);

        if(this.onWindowLoadSubscription){
            this.onWindowLoadSubscription.unsubscribe();
        }

        if(window.innerWidth > VIEW_WIDTH.MOBILE){
            window.removeEventListener("keyup", this.onKeyPress);
            document.removeEventListener('focus', this.handleFocus, true);
            document.removeEventListener('blur', this.handleBlur, true);
        }
    } 

    /**
    * DOCU: This will hide the report a mistake if there are no highlighted text. <br>
    * Triggered: componentDidMount, componentWillUnmount <br>
    * Last Updated Date: August 22, 2022
    * @function
    * @memberOf Course
    * @author Jerwin, Updated by Alfonso
    */     
    onResizeBrowser = () => {
        if(window.getSelection().toString() === ""){
            this.onHideReportCurriculumMistake();
        }
    }

    /**
    * DOCU: This will handle the next and previous button behavior. <br>
    * Triggered: componentDidMount, componentWillUnmount, ComponentDidUpdate <br>
    * Last Updated Date: Sep 11, 2023
    * @function
    * @memberOf Course
    * @param {object} event - For determining key press
    * @author Alfonso Demy
    */     
    onKeyPress = (event) => {
        let next_button = this.next_button.current;
        let prev_button = this.previous_button.current;
        /* Press right arrow key */
        if(!this.state.is_typing && event.keyCode === KEY_CODES.right_arrow){
            if(next_button && !next_button.classList.contains("disabled")){
                let { next_link } = this.props.courses.track;
                /* If it is the last module scroll to the button then animate. Else, go to the next module */
                if (next_link === "/courses" || next_link === "/t/courses"){
                    next_button.scrollIntoView();
                    next_button.classList.remove("animated", "tada");
                    void next_button.offsetWidth;
                    next_button.classList.add("animated", "tada");
                }
                else{
                    next_button.click();
                }
            }
        }
        /* Press left arrow key */
        else if(!this.state.is_typing && event.keyCode === KEY_CODES.left_arrow){
            if(prev_button &&!prev_button.classList.contains("disabled")){
                prev_button.click();
            }       
        }
    }

    /**
    * DOCU: This will handle the focus behavior. <br>
    * Triggered: componentDidMount, componentWillUnmount, ComponentDidUpdate <br>
    * Last Updated Date: February 23, 2023
    * @function
    * @memberOf Course
    * @param {object} event - For determining element
    * @author Alfonso
    */     
    handleFocus = (event) => {
        if (event.target.tagName === ELEMENT.input || 
            event.target.tagName === ELEMENT.textarea || 
            event.target.className === ELEMENT.ask_question_textarea || 
            event.target.className === ELEMENT.ask_question_edit_textarea){
            this.setState({ is_typing: true });
        }
    }

    /**
    * DOCU: This will handle the blur behavior. <br>
    * Triggered: componentDidMount, componentWillUnmount, ComponentDidUpdate <br>
    * Last Updated Date: February 23, 2023
    * @function
    * @memberOf Course
    * @author Alfonso
    */     
    handleBlur = () => {
        this.setState({ is_typing: false });
    }
    
    /**
    * DOCU: This will handle the like/dislike of a lesson module. <br>
    * Triggered: lesson.component <br>
    * Last Updated Date: September 27, 2020
    * @function
    * @memberOf Course
    * @param {object} event - Requires form data events
    * @param {object} active_module - selected module data
    * @author Jerwin
    */
    handleLikeDislikeValue = (event, module) => {
        let chapter_id = Number(this.props.match.params.chapter_id);
        let track = {...this.state.track}
        let chapter_index = track.chapters.map(chapter => {return chapter.chapter_id; }).indexOf(chapter_id);
        let active_module = track.chapters[chapter_index].chapter_modules.filter(chapter_module => chapter_module.chapter_module_id === module.chapter_module_id);
        active_module[0].is_liked = event.target.value === "like" ? true : false;
    }    
    
    /**
    * DOCU: This will return the content component that will depends on the type (Lesson, Todo, Quiz or Chapter survey) <br>
    * Triggered: Inside render()  <br>
    * Last Updated Date: September 19, 2022
    * @function
    * @memberOf Course
    * @param {object} active_module - selected module data
    * @author Jerwin, updated by Noah, Christian, Jomar
    */           
    renderModuleDescription = (active_module) => {
        if(this.props.is_online_discussion === "false"){
            const {track} = this.props.courses;

            const MODULE_COMPONENT = {
                0: <LessonComponent module={ active_module } 
                        onHandleLikeDislikeValue={this.handleLikeDislikeValue} 
                        onUserClickedAnElement={this.userClickedAnElement}
                        onCopyToClipboard={this.copyToClipboard}
                        onConvertPrismtoRainbowCodeSnippet={this.convertPrismtoRainbowCodeSnippet}
                        onGetTextSelection={this.getTextSelection}/>,
                1: <QuizComponent module={ active_module } 
                        quiz_details={this.props.courses.quiz_module} 
                        is_disable_see_result={this.props.courses.is_disable_see_result} 
                        track_id={this.props.match.params.track_id} 
                        course_id={this.props.courses.track.course_id}
                        user_track_id={this.props.courses.track.user_track_id} 
                        chapter_id={this.props.courses.track.chapter_id} 
                        stack_schedule_id={track.stack_schedule_id}
                        cc_stack_schedule_id={track.cc_stack_schedule_id}
                        startQuiz={this.props.startQuiz}
                        onAddActivityLog={this.props.onAddActivityLog}
                        next_link= {this.props.courses.track.next_link}
                        navigateQuiz={this.props.navigateQuiz} onUserClickedAnElement={this.userClickedAnElement}
                        onCopyToClipboard={this.copyToClipboard}
                        allow_track_progress={track.allow_track_progress}
                        onConvertPrismtoRainbowCodeSnippet={this.convertPrismtoRainbowCodeSnippet}
                        onGetTextSelection={this.getTextSelection}
                        is_submit_quiz_loading={this.props.courses.is_submit_quiz_loading}
                    />,
                2: <TodoComponent module={ active_module } 
                        assignment_tasks={this.props.courses.assignment_tasks} 
                        is_show_todo_submit={this.props.courses.track.is_show_todo_submit}
                        stack_schedule_id={track.stack_schedule_id}
                        onUserClickedAnElement={this.userClickedAnElement} 
                        completedSelectedTaskItem={this.completedSelectedTaskItem}
                        onCopyToClipboard={this.copyToClipboard}
                        onConvertPrismtoRainbowCodeSnippet={this.convertPrismtoRainbowCodeSnippet}
                        onGetTextSelection={this.getTextSelection}
                        allow_track_progress={track.allow_track_progress}
                        onAddActivityLog={this.props.onAddActivityLog}/>,
                3: <LessonComponent 
                        module={ active_module } 
                        onUserClickedAnElement={this.userClickedAnElement}
                        onCopyToClipboard={this.copyToClipboard}
                        onConvertPrismtoRainbowCodeSnippet={this.convertPrismtoRainbowCodeSnippet}
                        onGetTextSelection={this.getTextSelection}/>,
                4: <ChapterSurveyComponent content={ active_module } 
                        track_id={this.props.match.params.track_id} 
                        chapter_id={this.props.match.params.chapter_id}
                        is_submit_chapter_survey_form={this.state.is_submit_chapter_survey_form} />,
            };

            return MODULE_COMPONENT[active_module.module_type];
        }
        else{
            /* Showing of online discussion content */
            return <OnlineDiscussion onSubmitDiscussionAnswer={this.handleSubmitDiscussionAnswer} online_discussion={this.props.discussions} onAddActivityLog={this.props.onAddActivityLog} />;
        }
    }

    /**
    * DOCU: This will remove the bookmark of the module. <br>
    * Added also the tracking of activity logs when the user removes a bookmark for analytics purposes. <br>
    * Triggered: Inside render()  <br>
    * Last Updated Date: September 27, 2020
    * @function
    * @memberOf Course
    * @param {object} event - Requires form data events
    * @param {object} active_module - Requires chapter_module_id, bookmark_id and is_bookmarked data from selected active module.
    * @author Jerwin Updated by: Christian
    */  
    removeModuleBookmark = (event, active_module) =>{
        this.props.onRemoveBookmark(event, active_module.chapter_module_id, active_module.bookmark_id);
        active_module.is_bookmarked = false;

        /* Default: (Lesson) User un-bookmark a page. */
        let action_type = '1.1.20'; 
        if(active_module.module_type === ModuleTypes.QUIZ_MODULE){
            action_type = '1.5.20'; /* (Quiz) User un-bookmark a page. */
        }
        else if(active_module.module_type === ModuleTypes.TASK_MODULE){
            action_type = '1.4.20'; /* (TODO) User un-bookmark a page. */
        }

        this.props.onAddActivityLog(action_type);
    }

    /**
    * DOCU: Function to display the 'Add Bookmark Modal'.
    * Added also the tracking of activity logs when the user add a bookmark for analytics purposes. <br>
    * Triggered: Inside render()  <br>
    * Last Updated Date: November 16, 2020
    * @function
    * @memberOf Course
    * @param {object} active_module - Requires module_type to determinine what action type to log on the analytics.
    * @author Christian
    */  
    displayAddBookmarkModal = (active_module) => {
        toggleShowModal(this, "is_show_add_bookmark_modal", true);

        /* Default: (Lesson) User bookmarks the page and save bookmark modal will display. */
        let action_type = '1.1.19'; 
        if(active_module.module_type === ModuleTypes.QUIZ_MODULE){
            action_type = '1.5.19'; /* (Quiz) User bookmarks the page and save bookmark modal will display. */
        }
        else if(active_module.module_type === ModuleTypes.TASK_MODULE){
            action_type = '1.4.19'; /* (TODO) User bookmarks the page and save bookmark modal will display. */
        }

        this.props.onAddActivityLog(action_type);        
    }

    /** 
    * DOCU: Function that will detect if the user clicks an A-tag. <br>
    * This is being captured and saved to the Analytics DB. <br>
    * Triggered: Inside render()  <br>
    * Last Updated: April 12, 2020 <br>
    * @function
    * @memberOf Course
    * @param {object} event={} - Requires event object for checking the element that needs to captured for Analytics. <br>
    * @param {object} module_type - Need to provide the module type for us to know where the data comes from. <br>
    * @author Christian
    */
    userClickedAnElement = (event, module_type) => {
        if(event.target.tagName === "A"){
            if(module_type === "lesson"){
                /* (Lesson) User clicks a link. */
                this.props.onAddActivityLog("1.3.3");
            }
            else if(module_type === "quiz"){
                /* (Quiz) User clicks a link. */
                this.props.onAddActivityLog("1.5.3");
            }
            else if(module_type === "todo"){
                /* (TODO) User clicks a link. */
                this.props.onAddActivityLog("1.4.3");
            }
        }
        else if(event.target.tagName === "BUTTON" && event.target.className === "copy_code_snippet_btn"){
            if(module_type === "lesson"){
                /* (Lesson) User clicks the Copy */
                this.props.onAddActivityLog("1.3.4");
            }
            else if(module_type === "quiz"){
                /* (Quiz) User clicks the Copy */
                this.props.onAddActivityLog("1.5.4");
            }
            else if(module_type === "todo"){
                /* (TODO) User clicks the Copy */
                this.props.onAddActivityLog("1.4.4");
            }
        }
    }
 
    /** 
    * DOCU: Function to check if there is IFrame on the lesson page.<br>
    * Used for tracking play and pausing of Vimeo videos for analytics purposes. <br>
    * Triggered: Inside render()  <br>
    * Last Updated: November 18, 2020 <br>
    * @function
    * @memberOf Course
    * @author Christian
    */
    checkIframes = () => {
        let iframe_videos = document.getElementsByTagName("IFRAME");

        if(iframe_videos !== undefined){
            for(let index = 0; index < iframe_videos.length; index++){
                let video_src = iframe_videos[index].getAttribute("src");
                /*Check if src attribute is existing.*/
                /* For Vimeo Videos */
                if(video_src !== undefined && video_src !== null && video_src.includes(".vimeo.com")){
                    this.vimeoPlayers['player_'+index] = new Player(iframe_videos[index]);
                    this.vimeoPlayers['player_'+index].on('play', () => {this.userPlayPauseVideo(true)});
                    this.vimeoPlayers['player_'+index].on('pause', () => {this.userPlayPauseVideo(false)});
                }
                /* For YouTube Videos */
                if(video_src !== undefined && video_src !== null && video_src.includes(".youtube.com")){
                    /*TODO: Needs to check on how to detect events on youtube player. */
                }
            }
        }        
    }

    /** 
    * DOCU: Function to track the user if the video play/pause button was clicked.<br>
    * This is for analytics purposes. <br>
    * Triggered: Inside render()  <br>
    * Last Updated: November 18, 2020 <br>
    * @function
    * @memberOf Course
    * @param {boolean} is_playing - Requires is_playing to track the user action if they pause or play video.
    * @author Christian
    */
    userPlayPauseVideo = (is_playing) => {
        const {track} = this.props.courses;

        if(track.active_module.module_type === ModuleTypes.LESSON_MODULE){
            /*(Lesson) User clicks the play/pause button of embedded video*/
            this.props.onAddActivityLog( (is_playing) ? '1.3.1' : '1.3.2');
        }
        else if(track.active_module.module_type === ModuleTypes.TASK_MODULE){
            /*(TODO) User clicks the play/pause button of embedded video*/
            this.props.onAddActivityLog( (is_playing) ? '1.4.1' : '1.4.2');
        }
    }

    /** 
    * DOCU: Determine the next step before hitting the next link. <br>
    * Added also the tracking of activity logs when user submitted end chapter survey answer for analytics purposes. <br>
    * Example: Completing a lesson module and saving next. <br>
    * Triggered: Inside render()  <br>
    * Last Updated: October 3, 2023 <br>
    * @function
    * @memberOf Course
    * @param {boolean} is_admin_users - Requires is_admin_users to determine user level.
    * @author Christian, Updated by: Mario, Psyrone
    */
    processNextStepBeforeNextLink = (is_admin_users) => {
        const {track} = this.props.courses;

        if(track.active_module.module_type === ModuleTypes.LESSON_MODULE){
            this.props.history.push(track.next_link);
            this.clickPreviousNextLink("next");
        }
        else if(track.active_module.module_type === ModuleTypes.END_CHAPTER_SURVEY_MODULE){
            this.props.courses.track.is_chapter_survey_form_submitted = true;
            this.setState({ is_submit_chapter_survey_form: !this.state.is_submit_chapter_survey_form});

            /* (End Chapter Survey) User submit answer for end chapter survey */
            this.props.onAddActivityLog('3.10.18');
        }
    }

    /** 
    * DOCU: Function to complete a lesson module when next button is clicked. <br>
    * REVIEW: This method is no longer used on this component.
    * Triggered: inside render() <br>
    * Last Updated: October 3, 2023 <br>
    * @function
    * @memberOf Course
    * @author Mario Updated by Jomar
    */
    completeLessonModule = () => {
        const {track} = this.props.courses;
        this.props.completeLessonModule({
            track_id            : track.track_id, 
            course_id           : track.course_id, 
            chapter_id          : track.chapter_id, 
            chapter_module_id   : track.chapter_module_id,
            stack_schedule_id   : track.stack_schedule_id,
            user_track_id       : track.user_track_id,
            next_link           : track.next_link
        });
        
        this.props.history.push(track.next_link);
        this.clickPreviousNextLink("next");
    }

    /** 
    * DOCU: Function to fetch course page data after the saving of lesson in BE <br>
    * Triggered: inside render() <br>
    * Last Updated: May 23, 2022 <br>
    * @function
    * @memberOf Course
    * @author Jomar
    */
    afterCompleteLessonFetchCoursePageData = () => {

        this.props.courses.is_lesson_module_submitted = true;

        if(this.props.is_online_discussion === "true"){
            this.props.fetchOnlineDiscussion({
                track_id:     this.props.match.params.track_id,
                week_id:      this.props.match.params.week_id,
                question_id:  this.props.match.params.question_id,
            }); 
        }
        else{
            this.props.fetchCoursePageData({
                track_id:           this.props.match.params.track_id,
                chapter_id:         this.props.match.params.chapter_id,
                chapter_module_id:  this.props.match.params.module_id,
                trial_platform_mode: this.props.location?.pathname?.includes("/t/m/")
            });

            window.scrollTo(0, 0);     
        }
    }

    /** 
    * DOCU: Function to save user session activity logs for analytics purposes and hide report a mistake popover.<br>
    * Triggered: When user clicks the previous or next link. <br>
    * Last Updated: May 12, 2022 <br>
    * @function
    * @memberOf Course
    * @param {string} action - Requires action (next or previous)
    * @author Christian, UpdatedBy: Demy, Jomar
    */
    clickPreviousNextLink = (action) => {
        /* (Lesson) User clicks NEXT / PREVIOUS button and page content will change */
        this.props.onAddActivityLog( (action == "next") ? '1.3.8' : '1.3.7');
        this.onHideReportCurriculumMistake();

        setTimeout(() => {
            window.scrollTo({top: this.module_description_container_position.current?.offsetTop, behavior: "smooth"});
        }, 1000);
    }   

    /** 
    * DOCU: Function to save checked/uncheked assignment task.<br>
    * Triggered: TodoComponent <br>
    * Last Updated: March 30, 2022 <br>
    * @function
    * @memberOf Course
    * @param {integer} task_index - Requires index number of the selected task
    * @author Noah updated by Christian, Cesar
    */
    completedSelectedTaskItem = (task_index) => {
        let {track, assignment_tasks} = this.props.courses;
        
        if(track.active_module && track.is_show_todo_submit){
            if(assignment_tasks && assignment_tasks.length > 0){
                if(assignment_tasks[task_index]){
                    assignment_tasks[task_index].status =   assignment_tasks[task_index].status == 1 ? 0 : 1;
                }

                this.props.submitAssignmentTask({
                    id: track.user_module ? track.user_module[0]?.user_module_id : undefined,
                    track_id: track.track_id,  
                    chapter_module_id: track.chapter_module_id,
                    stack_schedule_id: track.stack_schedule_id,
                    cc_stack_schedule_id: track.cc_stack_schedule_id,
                    module_data: assignment_tasks,
                    is_optional: track.active_module?.checklist?.is_optional || null,
                    is_practice: track.active_module?.checklist?.is_practice || null
                });
            }
        }
    }

    /** 
    * DOCU: This will copy the selected code snippet.<br>
    * Triggered: components/lesson.component.jsx, components/quiz.component.jsx, components/todo.component.jsx <br>
    * Last Updated: January 25, 2022 <br>
    * @function
    * @memberOf Course
    * @param {object} event - Requires event to determine the element target.
    * @author Jerwin
    */
    copyToClipboard = (event) => {
        let selector = event.target;

        if(selector.className === "copy_code_snippet_btn"){
            let is_copied = false;
            let pre_text = selector.closest("pre").innerText;
            let copy_to_clipboard_value = document.querySelector("#copy_to_clipboard_value");
            pre_text = pre_text.substring(0, pre_text.length - 4);

            /* Copy the selected text on the temp #copy_to_clipboard_value textarea */ 
            copy_to_clipboard_value.value = pre_text;
            copy_to_clipboard_value.select();
            is_copied = document.execCommand("copy");
            
            /* Back to default state of copy button */ 
            if(is_copied){
                selector.innerHTML = "copied!";
                copy_to_clipboard_value.value = "";

                setTimeout(() => {
                    selector.innerHTML = "copy";
                }, TIMEOUT_SPEED.normal);
            }
        }
    }

    /**
    * DOCU: This function is to get the dimesion of selected/highlighted text <br>
    * Triggered: components/lesson.component.jsx, components/quiz.component.jsx, components/todo.component.jsx  <br>
    * Last Updated Date: March 15, 2023
    * @function
    * @memberOf Course
    * @returns {object} module_content_json - Requires module_content_json.description to be updated.
    * @author Jerwin, updated by Clifford
    */
    convertPrismtoRainbowCodeSnippet = (module_content_json) => {
        /* Converts some module_content_json that is not parsed. This will mostly occur on quiz module */
        if(typeof module_content_json === "string"){
            module_content_json = JSON.parse(module_content_json)
        }

        if(module_content_json !== ""){
            let temp_html = document.createElement( 'html' );
            temp_html.innerHTML = module_content_json.description;
            
            let selected_pre_element = temp_html.querySelectorAll('pre'); 
            if(selected_pre_element.length > 0){
                for (let [index, pre] of selected_pre_element.entries()) {
                    pre.removeAttribute("class");

                    /* HACK: This will correct the snippet language created by curriculum team */ 
                    if(pre.attributes.length !== 0){
                        if(pre.attributes[0].nodeValue === "c_sharp"){
                            pre.setAttribute("data-language", "csharp");
                        }
                        else if(pre.attributes[0].nodeValue === "bash"){
                            pre.setAttribute("data-language", "shell");
                        }
                    }

                    /* This will loop thru pre tag that is not yet converted to rainbow text highlight */ 
                    let unwrapped_pre_content = pre.textContent || pre.innerText;
                    pre.textContent = String(unwrapped_pre_content);
                    let temp_div_element = document.createElement('div');
                    temp_div_element.innerHTML = pre.outerHTML;

                    /* This will convert the code snippet and add a copy button */
                    if(window?.Rainbow){
                        window.Rainbow.color(temp_div_element, () => {
                            if(document.getElementsByTagName("pre")[index]){
                                document.getElementsByTagName("pre")[index].innerHTML = temp_div_element.firstChild.innerHTML + `<button class="copy_code_snippet_btn">copy</button>`;
                            }
                        });
                    } 
                }  

                module_content_json.description = temp_html.lastChild.innerHTML;
            }

            /* This will add target="_blank" on all links in module description. */ 
            module_content_json.description = this.convertAllLinksToNewTab(temp_html);
        }

        return module_content_json;
    }

    /**
    * DOCU: This function will add target="_blank" on all links in module description. <br>
    * Triggered: convertPrismtoRainbowCodeSnippet <br>
    * Last Updated Date: September 3, 2021
    * @function
    * @memberOf Course
    * @param {string} temp_html="" - HTML version of module description.
    * @author Jerwin
    */
    convertAllLinksToNewTab = (temp_html) => {
        let selected_a_element = temp_html.querySelectorAll("a"); 
        if(selected_a_element.length > 0){
            /* Loop thru all a tag */ 
            for (let [index, a_tag] of selected_a_element.entries()) {
                /* If the a tag element doesn't have target attribute. */ 
                if(!a_tag.hasAttribute("target")){
                    /* Add attribute target="_blank" -> This attribute will open new tab when the link is clicked/viewed */ 
                    a_tag.setAttribute("target", "_blank");
                }
            }
        }

        return temp_html.lastChild.innerHTML;
    }
    
    /**
    * DOCU: This function is to set the new comment discussion. <br>
    * Triggered: online_discussion.component <br>
    * Last Updated Date: December 19, 2023
    * @function
    * @memberOf Course
    * @param {string} value="" - Requires the value of textarea used from the `textarea_value` param.
    * @author Demy, Updated by: Christian, Psyrone
    */
    handleSubmitDiscussionAnswer = (textarea_value) => {
        if(textarea_value.trim() !== "" && this.props.discussions.selected_question_data !== null){

            this.props.saveDiscussionResponse({
                user_response: textarea_value,
                track_id: this.props.discussions.selected_question_data.track_id,
                discussion_id: this.props.discussions.selected_question_data.discussion_id,
                user_question_id: this.props.discussions.selected_question_data.id,
                week_number: this.props.discussions.selected_question_data.week_number
            });

            setTimeout(() => {
                window.scrollTo({top: this.discussion_position.current?.offsetTop, behavior: "smooth"});
            }, 1500);
        }
    }

    /**
    * DOCU: This function trigger for showing delete discussion modal <br>
    * Triggered: Course page, discussion content  <br>
    * Last Updated Date: March 5, 2021
    * @function
    * @memberOf Course
    * @param {string} data="" - Requires the data from discussion `online_discussion_item` param.
    * @author Demy, updated by Christian
    */
    onSubmitDeleteDiscussion = (event, active_discussion, active_response_index) =>{
        event.preventDefault();  
        
        this.props.deleteDiscussionResponse({
            user_response_id: active_discussion.id,
            user_question_id: active_discussion.user_question_id
        }, active_response_index);

        /* (Online Discussion) User delete a discussion response */
        this.props.onAddActivityLog("1.6.5");

        this.setState({ is_show_delete_discussion_modal: false });
        return false;
    }

    /**
    * DOCU: This function handle  for showing delete discussion modal <br>
    * Triggered: Course page, discussion content  <br>
    * Last Updated Date: March 2, 2021
    * @function
    * @memberOf Course
    * @author Demy
    */
    showDeleteDiscussionModal = () =>{
        this.setState({is_show_delete_discussion_modal:true})
    }


    /**
    * DOCU: This function is to show/hide report mistake curriculum popover if the user has selected/highlighted a text <br>
    * Triggered: lesson.component, todo.component and quiz.component  <br>
    * Last Updated Date: August 22, 2022
    * @function
    * @memberOf Course
    * @param {string} mouse_event="" - Requires the type of mouse event used from the `mouse_event` param.
    * @author Jerwin
    */
    getTextSelection = (mouse_event) => {
        /* Show the report a mistake popover */ 
        if(mouse_event === "mouseup" && document.getSelection().toString().length > 0){
            this.setState({ is_show_report_curriculum_popover: true });
        }

        /* Hide the report a mistake function */ 
        if(mouse_event === "mousedown"){
            (this.state.is_show_report_curriculum_popover) && this.setState({ is_show_report_curriculum_popover: false });
        }
    }

    /**
    * DOCU: This function will set the next button link. If the module is the last it will redirect to the next chapter. If the module is the last and there is no next chapter it will redirect to /tracks page <br>
    * Triggered: <div id="module_navigation_container">  <br>
    * Last Updated Date: September 11, 2023
    * @function
    * @memberOf Course
    * @param {integer} chapter_id - Requires the chapter id as a basis for updating button text
    * @param {string} next_link - Requires the next_link to determine the next chapter.
    * @author Jerwin, Updated by: CE, Demy
    */
    nextButtonText = ({chapter_id, next_link, is_next_track_course}) => {
        if(next_link){
            let next_link_chapter_id = next_link.split("/");
            if (next_link === "/courses" || is_next_track_course || next_link === "/t/courses" ){
                return "Finish this Unit";
            }    
            else if(chapter_id !== Number(next_link_chapter_id[next_link_chapter_id.length - 2])){
                return "Next Chapter";
            }
        }

        return "Next";
    }

    /**
    * DOCU: This function will set the next button link. If the module is the last it will redirect to the next chapter. If the module is the last and there is no next chapter it will redirect to /tracks page <br>
    * Triggered: <div id="module_navigation_container">  <br>
    * Last Updated Date: November 29, 2021
    * @function
    * @memberOf Course
    * @param {integer} chapter_id - Requires the chapter id as a basis for updating button text
    * @param {string} next_link - Requires the next_link to determine the next chapter.
    * @author Jerwin and Christian, updated by Cesar, Moved by: Demy
    */
    displayCheckSolutionsModal = (callback) => {
        toggleShowModal(this, "is_show_todo_solution_modal", true);

        this.props.onAddActivityLog("1.4.9");

        /* This will get the solution from Backend API. */
        this.getSolutionsFromGithub();
        this.setState({is_solution_countdown_completed: true});
    }

    /**
    * DOCU: Function to display the countdown timer and when it is completed display the check solutions button. <br>
    * Triggered: Inside render() <br>
    * Last Updated Date: November 29, 2021
    * @function
    * @memberOf TodoSubmit
    * @author Cesar, Moved by: Cesar
    */   
    displayCheckSolutionBtn = ({ hours, minutes, seconds, completed }) => {
        // if (completed) {
            /* return check solutions button when it is completed */
            return <button type="button" onClick={this.displayCheckSolutionsModal}>Check Solutions</button>
        // }
        // else {
        //     /* return a countdown timer */
        //     return <h4>Solution available in approximately {zeroPad(minutes) > 0 ? zeroPad(minutes) + " mins" : zeroPad(seconds) + " seconds"}</h4>
        // }
    }

    /** 
    * DOCU: Function to fetch solutions from Github. <br>
    * This is to fetch files inside the selected folder. <br>
    * Last Updated: September 15, 2021 <br>
    * @param {string} dir_path - Requires the directory path on where it will fetch the  files. <br>
    * @param {string} content_key_id - Need to provide the content key id for later use when appending the files to the Solution tree. <br>
    * @author Christian, Moved by: Demy
    */        
    getSolutionsFromGithub = (dir_path = null, content_key_id = null) => {
        let post_data = {};

        if(dir_path && content_key_id){
            post_data.dir_path = dir_path;
            post_data.content_key_id = content_key_id;
        }

        this.props.getToDoSolution(this.props.courses.track.chapter_module_id, post_data);
    }

    /**
    * DOCU: This function will hide the report curriculum popover <br>
    * Triggered: inside render()  <br>
    * Last Updated Date: October 7, 2021
    * @function
    * @memberOf Course
    * @author Mario
    */
    onHideReportCurriculumMistake = () => {
        this.setState({is_show_report_curriculum_popover: false});
    }
    
    /**
    * DOCU: This function check the icon of the mobile header <br>
    * Triggered: mobile side nav<br>
    * Last Updated Date: Dec 12, 2021
    * @function
    * @memberOf Course
    * @author Demy
    */
    getIconOnMobileView = (module_type, track) => {
        let icon_class = {};
        const MODULE_TYPE = {0: "lesson", 1: "quiz", 2: "assignment", 3: "admin_lesson", 4: "chapter_survey"};

        icon_class = MODULE_TYPE[module_type];
        
        if(track){
            /* check if assignment category is either practice or optional assignments */
            if(track.practice_assignments && track.practice_assignments.includes(track.chapter_module_id)){
                icon_class = "assignment_practice";
            }
            else if(track.optional_assignments && track.optional_assignments.includes(track.chapter_module_id)){
                icon_class = "assignment_optional";
            }
        }
      
        return icon_class;
    }

    /**
    * DOCU: This is a function which aims to pass values from child to this component. <br>
    * Triggered: Called on subnavigation and could be called by its child components  <br>
    * Last Updated Date: May 3, 2023
    * @function
    * @memberOf Course
    * @param {integer} notification_count
    * @param {array} raw_notifications
    * @param {function} markNotificationAsRead
    * @author JeffreyCarl
    */
    setStateFromChild = (notification_count, raw_notifications, markNotificationAsRead) => {
        this.setState({ notification_count, raw_notifications, markNotificationAsRead });
    }

    /**
    * DOCU: Function to update the content from the new active tab. <br>
    * Last Updated: November 29, 2021 <br>
    * @param {object} active_tab_content={} - Requires the the whole content from the newly selected active tab. <br>
    * @author Christian, Moved by: Cesar
    */
    changeActiveTabContent = (active_tab_content) => {
        this.props.changeActiveTabContent(active_tab_content);
    }

    render() { 
        let { notification_count, raw_notifications, markNotificationAsRead, profile, is_show_todo_solution_modal, is_show_survey_modal } = this.state;
        let { track, is_show_check_solution_timer, disable_next_previous_button, is_lesson_module_submitted, is_late_assignment, is_disable_see_result, is_submit_quiz_loading } = this.props.courses;
        let { course_start_date, is_major_track } = track;
        const { selected_question_data, discussion_breadcrumbs } = this.props.discussions;
        const { company_id: workspace_id } = this.props.user?.user_details?.workspace || { company_id: 1 };
        let dTimezone = new Date();
        let offset = dTimezone.getTimezoneOffset() / 60;
        let parsed_course_start_date = (!is_major_track || (is_major_track && !course_start_date)) ? new Date() : new Date(Date.parse(course_start_date));
        parsed_course_start_date && parsed_course_start_date.setHours(parsed_course_start_date.getHours() + offset);


        /* DOCU: This will check if the completed lesson was finished saving in the BE before fetching the next course data, 
            can not be truthy because is_lesson_module_submitted was only defined after submission of lesson module the rest of the cycle is undefined, 
            turning the condition truthy will cause infinite loop
        */
        if(is_lesson_module_submitted === false){
            this.afterCompleteLessonFetchCoursePageData();
        }

        /* If there are survey schedules for the student, update the state to trigger the survey modal */ 
        if(track.cd_survey_scheds){
            this.state.cd_survey_scheds = track.cd_survey_scheds;
        }

        let is_admin_users = (profile) ? ADMIN_USERS.includes(this.state.profile.general.user_level_id) : false;
        
        /* Determine if user allowed to proceed on the next link */
        let save_before_trigger_next_link = track.active_module !== undefined && [ModuleTypes.LESSON_MODULE, ModuleTypes.END_CHAPTER_SURVEY_MODULE].includes(track.active_module.module_type);
        /* Redirect to next link if Student currently in Survey Module */
        if(track.proceed_to_next_link && window.location.pathname.includes("/survey")){
            this.props.history.push(track.next_link);
        }

        /* If proceed to new url is defined and value is true, it means the initial URL was an Admin module and URL should be updated */ 
        /* Commented out the updating of URL while we are still investigating the cause of issue encountered by Eco 
            if(track.proceed_to_new_url !== undefined && track.proceed_to_new_url === true){
                this.props.history.push(track.new_url);
            }
        */ 

        if(this.props.is_online_discussion === "true" && selected_question_data
            && selected_question_data.id
            && this.state.current_discussion_question_id !== selected_question_data.id 
            && this.props.analytics.is_session_page_saving === false
        ){
            if(this.props.analytics.is_session_page_updating === false){
                /*  Update the time stamp of the current user session page record for analytics purposes. */
                this.props.onUpdateUserSessionPage();
            }

            this.setState({current_discussion_question_id: selected_question_data.id});
            this.props.onSaveUserSessionPage({
                page_type_id        : MODULES_PAGE,
                track_id            : selected_question_data.track_id, 
                course_id           : selected_question_data.discussion_id, 
                chapter_id          : selected_question_data.id, 
                chapter_module_id   : 0,                
            });

            /* Added delay for checking of IFrames after it was loaded to trigger checking when user clicks the NEXT/PREVIOUS. */
            setTimeout(() => { this.checkIframes(); }, 200);
        }

        let get_user_details = getUserDetailsFromToken();
        let sence_session = get_user_details.status ? get_user_details.user_details.workspace_custom_data?.sence_integration : null;

        /* Will check if the user is a trial mode */
        let is_trial_platform_mode = this.props.user?.user_details?.general?.trial_platform_mode;

        return (    
            <React.Fragment>
                {/* USE to update the Page Title in Unit page for Unit */}
                {track.active_module && track.active_chapter && <HeadComponent title={`${track.active_module.module_type === ModuleTypes.END_CHAPTER_SURVEY_MODULE ? 'Chapter Survey' : track.active_module.chapter_module_title} | ${track.active_chapter.chapter_title}`} />} 
                {/* USE to update the Page Title in Unit page for Discussion */}
                {discussion_breadcrumbs[1] && discussion_breadcrumbs[2] && <HeadComponent title={`${discussion_breadcrumbs[2]} | ${discussion_breadcrumbs[1]}`} />}

                <ReportCurriculumMistake 
                    is_show={this.state.is_show_report_curriculum_popover} 
                    track={this.props.courses.track} 
                    onHideReportCurriculumMistake={this.onHideReportCurriculumMistake} />

                <HeaderComponent 
                    className={`container ${this.state.mobile_show_module ? "show_module" : "hide_module"}`}
                    user_info={this.state.profile} 
                    onAddActivityLog={this.props.onAddActivityLog} 
                    onAddBookmark={this.props.onAddBookmark} 
                    onRemoveBookmark={this.props.onRemoveBookmark}
                    history={this.props.history} 
                    is_dark_mode={this.props.is_dark_mode}
                    handleOnChangeDarkMode={this.props.handleOnChangeDarkMode} 
                    onUpdateUserSessionPage={this.props.onUpdateUserSessionPage}/>

                {sence_session?.is_sence_session_on && this.state.is_show_sence_countdown_timer && <ChileCountdownBanner 
                    history={this.props.history}
                    updateParentStateFromChild={(to_update) => {this.setState(to_update)}}/>}

                <div  className={`container ${this.state.mobile_show_module ? "show_module" : "hide_module"}`} id="course_container">
                    <SubNavigationComponent 
                        bookmarks={this.props.bookmarks}
                        handleOnChangeDarkMode={this.props.handleOnChangeDarkMode}
                        history={this.props.history}
                        is_dark_mode={this.props.is_dark_mode}
                        is_show_links={true}
                        location={this.props.location.pathname} 
                        onAddActivityLog={this.props.onAddActivityLog}
                        onAddBookmark={this.props.onAddBookmark}
                        onRemoveBookmark={this.props.onRemoveBookmark}
                        setStateFromChild={this.setStateFromChild}
                    /> 

                    {/* USE for ONLINE DISCUSSION sidebar */}
                    {(this.props.is_online_discussion === "true") && 
                        <SidebarOnlineDiscussionComponent
                            mobileShowModule={() => this.setState({mobile_show_module: true})}
                            pathParams={this.props.match.params} 
                            online_discussion={this.props.discussions} 
                            user_level_id={(this.state.profile?.general?.user_level_id) ? this.state.profile.general.user_level_id : ""} 
                            onAddActivityLog={this.props.onAddActivityLog}/>
                    }
                    {/* USE for normal COURSE Sidebar */}
                    {(this.props.is_online_discussion === "false") && 
                        <SidebarComponent
                            mobileShowModule={() => this.setState({mobile_show_module: true})}
                            pathParams={this.props.match.params} 
                            track={track} 
                            user_level_id={(this.state.profile?.general?.user_level_id) ? this.state.profile.general.user_level_id : ""} 
                            onAddActivityLog={this.props.onAddActivityLog}
                            />
                    }

                    <div id="module_description_container" className={`right_container ${(this.props.is_online_discussion === "true") ? "is_online_discussion" : "" }`} ref={this.module_description_container_position}>
                        {/* USE for ONLINE DISCUSSION */}
                        {(this.props.is_online_discussion === "true") && 
                            <div className="content_header">
                                <MediaQuery minWidth={769}>
                                    <h1 className="module_name">{(this.props.discussions?.discussion_breadcrumbs) ? this.props.discussions.discussion_breadcrumbs[2] : null }</h1>
                                </MediaQuery>
                                <MediaQuery maxWidth={768}>
                                    <button id="course_back_btn" type="button" onClick={() => this.setState({mobile_show_module: false})}><FontAwesomeIcon icon={["fas", "chevron-left"]}/></button>
                                    <h2 id="mobile_title">
                                        {(this.props.discussions?.discussion_breadcrumbs !== undefined) ? this.props.discussions.discussion_breadcrumbs[2] : null }
                                    </h2>
                                </MediaQuery>
                                { is_admin_users === true &&
                                    <div id="online_discussion_cta_container">
                                        <button type="button" className="edit_discussion_btn" onClick={() => this.setState({is_show_edit_discussion_board_modal:true})}></button>
                                        <button type="button" className="delete_discussion_btn" onClick={() => this.setState({is_show_delete_discussion_module_modal:true})}></button>
                                    </div>
                                }
                            </div>
                        }
                                                
                        {track.chapter_module_id === 'survey' &&
                        <div className="content_header">
                            <MediaQuery maxWidth={768}>
                                <button id="course_back_btn" type="button" onClick={() => this.setState({mobile_show_module: false})}><FontAwesomeIcon icon={["fas", "chevron-left"]}/></button>
                                <h2 id="mobile_title">
                                    <span className={`icon ${this.getIconOnMobileView(track.active_module.module_type, track)}`}></span>
                                    Survey
                                </h2>
                            </MediaQuery>
                        </div>
                        }
                        {track.active_chapter !== undefined && track.chapter_module_id !== 'survey' &&
                            <div className="content_header">
                                <MediaQuery maxWidth={768}>
                                    <button id="course_back_btn" type="button" onClick={() => this.setState({mobile_show_module: false})}><FontAwesomeIcon icon={["fas", "chevron-left"]}/></button>
                                    <h2 id="mobile_title">
                                        <span className={`icon ${this.getIconOnMobileView(track.active_module.module_type, track)}`}></span>
                                        {track.active_module.chapter_module_title}
                                    </h2>
                                </MediaQuery>
                                <button id="bookmark_module_btn" className={`${ (track.active_module && track.active_module.bookmark_id  && track.active_module.is_bookmarked === true) ? "bookmarked_module_fill" : "" }`} type='button' onClick={(event) => 
                                    (track.active_module && track.active_module.bookmark_id && track.active_module.is_bookmarked === true)
                                    ? this.removeModuleBookmark(event, track.active_module)
                                    : this.displayAddBookmarkModal(track.active_module)
                                }></button>
                            </div>
                        }
                        {is_admin_users === true && track.active_chapter !== undefined && track.active_module.module_type === 1 &&
                            <a target='_blank' id="course_edit_btn" href={`/modules/edit/${track.track_id}/${track.active_chapter.chapter_id}/${track.active_module.chapter_module_id}`}></a>
                        }
                        
                        <div className="content" onScroll={() => (this.state.is_show_report_curriculum_popover) && this.setState({ is_show_report_curriculum_popover: false }) }>
                            { (track.active_module !== undefined && track.active_module.content !== null) ? this.renderModuleDescription(track.active_module) : null }
                            { (this.props.is_online_discussion === "true") ? this.renderModuleDescription("online_discussion") : null }
                        </div>
                        
                        {/* Copy to clipboard textarea */}
                        <textarea id="copy_to_clipboard_value"></textarea>
                    </div>

                    {/*  Show FAQ for Lesson Module's only track.faqs.faq_count > 0 &&  */}
                    {( track.active_module !== undefined 
                        && track.active_module.content !== undefined && track.active_module.module_type === 0 
                        && track.faqs !== undefined && track.faqs.faq_count > 0
                        )> 0 &&
                        <FrequentlyAskedQuestionComponent active_module={track.active_module} onAddActivityLog={this.props.onAddActivityLog}/>
                    }

                    {/*  Show Submit and Check Solution for Todo Module's only */}
                    {(track.active_module !== undefined && track.active_module.module_type === ModuleTypes.TASK_MODULE &&  (parsed_course_start_date <= new Date() || WORKSPACE_ALLOWED_ASSIGNMENT_EARLY_ACCESS[`${workspace_id}`]) )> 0 &&
                        <TodoSubmitComponent 
                            user_profile={profile} 
                            toggleShowModal={(event => toggleShowModal(this, "is_show_todo_solution_modal", false))} 
                            is_show_todo_solution_modal={is_show_todo_solution_modal} 
                            getSolutionsFromGithub={this.getSolutionsFromGithub} 
                            onAddActivityLog={this.props.onAddActivityLog}
                            is_show_todo_submit={track.is_show_todo_submit}
                            updateParentStateFromChild={(object) => this.setState(object)}
                        />
                    }

                    {/* DOCU: Assignment Forum is requested to be hidden until further notice
                    https://codingdojo.slack.com/archives/C046UQEPZRV/p1677188181777369 */}
                    {/* {(track.is_show_todo_submit && this.state.is_show_assignment_forum) && 
                        <AssignmentForumComponent 
                            pathParams={this.props.match.params} 
                            history={this.props.history}
                            raw_notifications={raw_notifications}
                            notification_count={notification_count}
                            markNotificationAsRead={markNotificationAsRead}
                        />
                    } */}

                    {this.props.is_online_discussion === "true" &&                    
                        <OnlineDiscussionCommentComponent
                            mobile_show_module={this.state.mobile_show_module}
                            online_discussion={this.props.discussions}
                            is_show_delete_discussion_modal= {this.state.is_show_delete_discussion_modal}
                            toggleShowModal={(event) => toggleShowModal(this, "is_show_delete_discussion_modal", false)}
                            handleOnSubmitDeleteDiscussion={this.onSubmitDeleteDiscussion}
                            handleDeleteDiscussionModal={this.showDeleteDiscussionModal}
                            onAddActivityLog={this.props.onAddActivityLog}
                            discussion_position = {this.discussion_position}
                        />
                    }
                    
                    {/* Show Solutions Modal */}
                    { (this.props.courses.track.todo_solution) && (parsed_course_start_date <= new Date() || WORKSPACE_ALLOWED_ASSIGNMENT_EARLY_ACCESS[`${workspace_id}`]) &&
                        <div id="check_solutions_container">
                            <button type="button" onClick={this.displayCheckSolutionsModal}>Check Solutions</button>
                        </div>
                    }

                    {this.props.is_online_discussion === "true" && this.props.discussions.selected_question_data !== null &&
                        <div id="module_navigation_container" className={`${ (this.props.discussions.selected_question_data?.previous_link === '/courses') ? "flex_end" : "" }`}>
                            { this.props.discussions.selected_question_data?.previous_link !== '/courses' 
                                && <Link 
                                        to={{pathname: this.props.discussions.selected_question_data?.previous_link}} 
                                        onClick={() => this.clickPreviousNextLink("previous")}
                                        innerRef={this.previous_button}
                                    >
                                        <FontAwesomeIcon icon={["fas", "chevron-left"]}/> Previous
                                    </Link>
                            }
                            {   <Link
                                    to={{pathname: this.props.discussions.selected_question_data.next_link}} 
                                    onClick={() => this.clickPreviousNextLink("next")} 
                                    className="btn btn-primary"
                                    innerRef={this.next_button}
                                >
                                    Next <FontAwesomeIcon icon={["fas", "chevron-right"]} />
                                </Link>
                            }  
                        </div>                    
                    }

                    {this.props.is_online_discussion === "false" &&                        
                       <div id="module_navigation_container" className={`${ (track.previous_link === (is_trial_platform_mode ? "/t/courses" : "/courses")) ? "flex_end" : "" }`}>
                            { (track.previous_link !== (is_trial_platform_mode ? "/t/courses" : "/courses") )
                                && <Link 
                                        to={{pathname: track.previous_link}} 
                                        className={`${ (track.active_module === undefined || disable_next_previous_button) ? "disabled" : "" }`} 
                                        onClick={() => this.clickPreviousNextLink("previous")}
                                        innerRef={this.previous_button}
                                    >
                                        <FontAwesomeIcon icon={["fas", "chevron-left"]}/> Previous
                                    </Link>
                            }
                            { 
                                (save_before_trigger_next_link === true && [ModuleTypes.LESSON_MODULE, ModuleTypes.END_CHAPTER_SURVEY_MODULE].includes(track.active_module.module_type) === true) 
                                    ?   (<button type="button"
                                                className={`btn btn-primary ${ (track.active_module === undefined || disable_next_previous_button) ? "disabled" : "" }`} /* Disable the next button when track.active_module is not ready or when they click this button */
                                                onClick={() =>{ this.processNextStepBeforeNextLink(is_admin_users) }}
                                                ref={this.next_button}
                                        >
                                            { this.nextButtonText(track) } <FontAwesomeIcon icon={["fas", "chevron-right"]}/>
                                        </button>)
                                    :   (<Link to={{pathname: track.next_link}} 
                                            onClick={() => this.clickPreviousNextLink("next")} 
                                            className={`btn btn-primary ${ (track.active_module === undefined || disable_next_previous_button) ? "disabled" : "" }`} 
                                            innerRef={this.next_button}
                                        > {/* Disable the next button when track.active_module is not ready or when they click this button */}
                                            { this.nextButtonText(track) } <FontAwesomeIcon icon={["fas", "chevron-right"]}/>
                                        </Link>)
                            }  
                        </div>
                    }                    
                    <div id="privacy_policy_container">
                        <button type="button" onClick={()=> toggleShowModal(this, "is_show_privacy_policy_modal", true)}>Privacy Policy</button>
                    </div>
                </div>

                {this.props.is_online_discussion === "true" && is_admin_users === true &&                  
                    <React.Fragment>
                        <EditDiscussionBoardModal 
                            toggleShowModal={() => toggleShowModal(this, "is_show_edit_discussion_board_modal", false)}
                            show={this.state.is_show_edit_discussion_board_modal}/>
                        <DeleteDiscussionModuleModal 
                            toggleShowModal={() => toggleShowModal(this, "is_show_delete_discussion_module_modal", false)}
                            show={this.state.is_show_delete_discussion_module_modal}/>
                    </React.Fragment>
                }

                <PrivacyPolicyModal
                    show={this.state.is_show_privacy_policy_modal} 
                    toggleShowModal={() => toggleShowModal(this, "is_show_privacy_policy_modal", false)}/>
                
                {track.active_chapter !== undefined && track.chapter_module_id !== 'survey' &&
                    <AddBookmarkModal 
                        active_module={track.active_module}
                        track_course_id={track.track_course_id}
                        chapter_details={{id: track.active_chapter.chapter_id, title: track.active_chapter.chapter_title}}
                        track_details={{id: track.track_id, title: track.track_title}}
                        show={this.state.is_show_add_bookmark_modal} 
                        toggleShowModal={() => toggleShowModal(this, "is_show_add_bookmark_modal", false)}
                        onAddActivityLog={this.props.onAddActivityLog}
                        is_track_overview={false}
                    />
                } 

                {/* Only show the Todo Modal if the module type is TODO */}
                {(track.active_module !== undefined && track.active_module.module_type === ModuleTypes.TASK_MODULE && track.has_ongoing_assignment !== undefined) &&
                    <TodoModal 
                        todo_title={track.active_module.chapter_module_title}
                        show={track.is_show_todo_modal}
                        has_ongoing_assignment={track.has_ongoing_assignment}
                        toggleShowModal={() => toggleShowModal(this, "is_show_todo_modal", false)}
                        onAddActivityLog={this.props.onAddActivityLog}/>
                    }

                {/* Only show the survey modal if there are active survey schedules */}
                {(is_show_survey_modal && this.state.cd_survey_scheds?.length && this.state.profile?.workspace_custom_data?.stack_info) ?
                    <SurveyModal 
                        show={is_show_survey_modal} 
                        surveys={this.state.cd_survey_scheds}
                        user_info={this.state.profile}
                        current_url={this.props.match.params}
                        hideModal={this.hideModal}
                        toggleShowModal={(event) => toggleShowModal(this, "is_show_survey_modal", false)}
                        onAddActivityLog={this.props.onAddActivityLog}/> : ""
                }

                {/* Show solutions modal file content */}
                {this.props.courses.track.todo_solution && 
                    <React.Fragment>
                        <TodoSolutionModal 
                            show={this.state.is_show_todo_solution_modal}
                            todo_solution={this.props.courses.track.todo_solution}
                            toggleShowModal={(event) => toggleShowModal(this, "is_show_todo_solution_modal", false)}
                            onAddActivityLog={this.props.onAddActivityLog}
                            getSolutionsFromGithub={this.getSolutionsFromGithub}
                            changeActiveTabContent={this.changeActiveTabContent}
                            chapter_module_id={this.props.courses.track.chapter_module_id}
                            getToDoSolutionFileContent={this.props.getToDoSolutionFileContent}
                            changeActiveFileContent={this.props.changeActiveFileContent}
                            active_module={this.props.courses.track.active_module}
                            is_dark_mode={this.props.is_dark_mode}/>   
                    </React.Fragment>
                }

                {/* When a user has a Sence access but hasn't logged in to their Sence account yet and tries to access the course page. */}
                {this.state.is_show_failed_sence_login_modal && sence_session && !sence_session?.has_expired && <FailedSenceLoginModal
                    show={this.state.is_show_failed_sence_login_modal}
                    toggleShowModal={() => toggleShowModal(this, "is_show_failed_sence_login_modal", false)}
                    history={this.props.history}
                    user_details={this.state.profile}/>}

                {/* When a user has a Sence access but failed to start their Sence session and tries to access the course page. */}
                {sence_session && (this.state.is_show_failed_sence_request_modal || sence_session?.has_expired && sence_session?.cd_stack_ids.includes(parseInt(this.props.location.pathname.split('/')[2]))) && <FailedSenceRequestModal
                    show={this.state.is_show_failed_sence_request_modal || sence_session?.has_expired} 
                    toggleShowModal={() => toggleShowModal(this, "is_show_failed_sence_request_modal", false)}
                    showFailedSenceModal={() => this.setState({is_show_failed_sence_login_modal: true})}
                    is_sence_session_expired={sence_session?.has_expired}
                    error_code={this.state.error_code}
                    updateParentStateFromChild={(to_update) => {this.setState(to_update)}}
                    history={this.props.history}/>}
            </React.Fragment>
        );
    }
}

let {fetchCoursePageData, completeLessonModule, submitAssignmentTask, changePropsValue, startQuiz, navigateQuiz} = CourseActions;
let {fetchOnlineDiscussion, saveDiscussionResponse, deleteDiscussionResponse} = DiscussionsActions;
let { changeActiveFileContent, changeActiveTabContent, getToDoSolution, getToDoSolutionFileContent } = ToDoActions;

const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps(['courses', 'analytics', 'discussions', "user"], { 
    fetchCoursePageData, completeLessonModule, submitAssignmentTask, changePropsValue, startQuiz, navigateQuiz,
    getToDoSolution, getToDoSolutionFileContent, changeActiveFileContent, changeActiveTabContent,
    fetchOnlineDiscussion, saveDiscussionResponse, deleteDiscussionResponse
});
export default connect(mapStateToProps, mapDispatchToProps)(Course);
